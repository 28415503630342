import { Pipe, PipeTransform } from '@angular/core'
import { ReportState } from '../utils/reportStates'

@Pipe({
  name: 'reportState'
})
export class ReportStatePipe implements PipeTransform {
  transform(value: number | undefined): string {
    if (value == ReportState.NEW) {
      return 'Novo'
    } else if (value == ReportState.ONGOING) {
      return 'Em Curso'
    } else if (value == ReportState.WAITINGAPPROVAL) {
      return 'Aguarda Assinatura'
    } else if (value == ReportState.SIGNED) {
      return 'Assinado'
    }

    return ''
  }
}
