import { Pipe, PipeTransform } from '@angular/core'
import { ExamTypesService } from '../services/exam-types.service'

@Pipe({
  name: 'examType'
})
export class ExamTypePipe implements PipeTransform {
  constructor(private examTypesService: ExamTypesService) { }

  transform(value: number): string {
    return this.examTypesService.getNameById(value) ?? ''
  }
}
