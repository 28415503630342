import { Pipe, PipeTransform } from '@angular/core'
import { AppointmentTypes } from '../utils/appointmentType'
import { Appointment } from '../models/appointment'
import { AppointmentUtils } from '../utils/appointmentUtils'
import { ExamTypesService } from '../services/exam-types.service'

@Pipe({
  name: 'appointmentType'
})
export class AppointmentTypePipe implements PipeTransform {
  constructor(private examTypesService: ExamTypesService) { }

  transform(appointmentTypeId: number, examTypeId?: number): string {
    if (appointmentTypeId == AppointmentTypes.EXAM) {
      if (examTypeId) return 'Exame ' + this.examTypesService.getNameById(examTypeId)
      return 'Exame'
    } else if (appointmentTypeId === AppointmentTypes.OFFLINE) {
      return 'Consulta Presencial'
    } else if (appointmentTypeId === AppointmentTypes.ONLINE) {
      return 'Consulta Online'
    } else if (appointmentTypeId === AppointmentTypes.CONTROL) {
      return 'Consulta Controlo'
    } else {
      return 'Consulta'
    }
  }
}

@Pipe({
  name: 'appointmentTypeExtraInfo'
})
export class AppointmentTypeWithExtraInfoPipe implements PipeTransform {
  constructor(private examTypesService: ExamTypesService) { }

  transform(appointment: Appointment, examTypeId?: number): string {
    let appointmentTypeId = appointment.appointment_type_id
    if (appointmentTypeId == AppointmentTypes.EXAM) {
      if (examTypeId) return 'Exame ' + this.examTypesService.getNameById(examTypeId)
      return 'Exame'
    } else if (appointmentTypeId === AppointmentTypes.OFFLINE) {
      return 'Consulta Presencial' + (AppointmentUtils.isAppointmentFirstAppointment(appointment) ? ' (1ª Consulta)' : '')
    } else if (appointmentTypeId === AppointmentTypes.ONLINE) {
      return 'Consulta Online' + (AppointmentUtils.isAppointmentFirstAppointment(appointment) ? ' (1ª Consulta)' : '')
    } else if (appointmentTypeId === AppointmentTypes.CONTROL) {
      return 'Consulta Controlo'
    } else {
      return 'Consulta'
    }
  }
}