import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { Subscription, filter } from 'rxjs'
import { AuthService } from '../../services/auth.service'
import { SidebarLoaderService } from '../../services/sidebar-loader.service'
import RoleGroups from 'src/app/shared/constants/roleGroups'
import { GenericRealTimeService } from 'src/app/services/generic-real-time.service'
import { TicketsRealTimeService } from 'src/app/services/tickets-real-time.service'
import { Account } from 'src/app/models/account'

@Component({
  selector: 'app-default-sidebar',
  templateUrl: './default-sidebar.component.html',
  styleUrls: ['./default-sidebar.component.scss']
})
export class DefaultSidebarComponent implements OnInit, OnChanges, OnDestroy {
  constructor(
    public router: Router,
    private authService: AuthService,
    private sidebarLoaderService: SidebarLoaderService,
    private genericRealTimeService: GenericRealTimeService,
    private ticketsRealTimeService: TicketsRealTimeService
  ) { }

  @Input()
  collapsed = false
  roleGroups = RoleGroups

  //sidebar sub menus visibility
  showExamsSubMenu = false
  showTicketsSubMenu = false
  showManagementSubMenu = false
  showToolsSubMenu = false
  showTeleheartSubMenu = false
  showMCDTSubMenu = false
  examsToRepeatCount = 0
  newExamToRepeatSub!: Subscription
  hasUnseenNotifications = 0
  hasUnseenNotificationsSub!: Subscription
  loggedInUserInfo!: Account
  hasAccessToAppointments = false

  // detect if user has changed route while sidebar is collapsed. if so, all submenus are closed
  private sub = this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe(event => {
      if (this.collapsed) {
        this.closeAllSubMenus()
      }
      this.sidebarLoaderService.closeSidebar()
    })

  ngOnInit(): void {
    this.loggedInUserInfo = this.authService.getUserInfo()
    this.newExamToRepeatSub = this.genericRealTimeService.examsToRepeatCountSub.subscribe(
      (count: number) => {
        this.examsToRepeatCount += count
      }
    )

    this.hasAccessToAppointments = this.loggedInUserInfo.organization.appointment_types > 0

    this.hasUnseenNotificationsSub = this.ticketsRealTimeService.unreadTicketsCount.subscribe(
      (hasUnseenNotifications: number) => {
        this.hasUnseenNotifications = hasUnseenNotifications
      }
    )
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.closeAllSubMenus()
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
    if (this.newExamToRepeatSub) {
      this.newExamToRepeatSub.unsubscribe()
    }
  }

  hideForSpecificAccounts(accountIds: number[]) {
    return accountIds.includes(this.loggedInUserInfo.id)
  }

  userLogout() {
    this.authService.logout()
  }

  /* MENU CLICK EVENTS */

  // collapse all submenus
  closeAllSubMenus() {
    this.showExamsSubMenu = false
    this.showTicketsSubMenu = false
    this.showManagementSubMenu = false
    this.showToolsSubMenu = false
    this.showTeleheartSubMenu = false
  }

  closeAllSubMenusOnMouseLeaveSidebar() {
    if (this.collapsed) {
      this.closeAllSubMenus()
    }
  }

  // expand/collaps exams submenu
  toggleExamsSubMenu() {
    const currentState = this.showExamsSubMenu
    this.closeAllSubMenus()
    this.showExamsSubMenu = !currentState
  }

  // expand/collaps teleheart submenu
  toggleTeleheartSubMenu() {
    const currentState = this.showTeleheartSubMenu
    this.closeAllSubMenus()
    this.showTeleheartSubMenu = !currentState
  }

  // expand/collaps tickets submenu
  toggleTicketsSubMenu() {
    const currentState = this.showTicketsSubMenu
    this.closeAllSubMenus()
    this.showTicketsSubMenu = !currentState
  }

  // expand/collapse mcdt submenu
  toggleMCDTSubMenu() {
    const currentState = this.showMCDTSubMenu
    this.closeAllSubMenus()
    this.showMCDTSubMenu = !currentState
  }

  // expand/collapse management submenu
  toggleManagementSubMenu() {
    const currentState = this.showManagementSubMenu
    this.closeAllSubMenus()
    this.showManagementSubMenu = !currentState
  }

  // expand/collapse tools submenu
  toggleToolsSubMenu() {
    const currentState = this.showToolsSubMenu
    this.closeAllSubMenus()
    this.showToolsSubMenu = !currentState
  }

  /* MENU CLICK EVENTS */

  // mouse over menu exams item
  mouseOverExamsItem() {
    if (this.collapsed) {
      this.closeAllSubMenus()
      this.showExamsSubMenu = true
    }
  }

  // mouse over menu tickets item
  mouseOverTicketsItem() {
    if (this.collapsed) {
      this.closeAllSubMenus()
      this.showTicketsSubMenu = true
    }
  }

  // mouse over menu management item
  mouseOverManagementItem() {
    if (this.collapsed) {
      this.closeAllSubMenus()
      this.showManagementSubMenu = true
    }
  }

  mouseOverAppointmentsItem() {
    if (this.collapsed) {
      this.closeAllSubMenus()
      this.showTeleheartSubMenu = true
    }
  }

  // mouse over menu tools item
  mouseOverToolsItem() {
    if (this.collapsed) {
      this.closeAllSubMenus()
      this.showToolsSubMenu = true
    }
  }
}
