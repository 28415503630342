// src/app/services/exam-types.service.ts
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ExamType } from '../models/exam-type';
import { environment } from 'src/environments/environment';

@Injectable({
   providedIn: 'root'
})
export class ExamTypesService {
   private url = `${environment.url}/exam-types/`

   private examTypesSubject = new BehaviorSubject<ExamType[] | null>(null);
   private publicExamTypesSubject = new BehaviorSubject<ExamType[] | null>(null);
   examTypes$ = this.examTypesSubject.asObservable();

   constructor(private http: HttpClient) { }

   async fetchExamTypes(): Promise<void> {
      const examTypes = await firstValueFrom(
         this.http.get<ExamType[]>(this.url).pipe(
            catchError(error => {
               console.error('Failed to fetch exam types:', error);
               throw error;
            })
         )
      );
      this.examTypesSubject.next(examTypes || null);
      this.publicExamTypesSubject.next(examTypes?.filter(type => type.is_public) || null);
   }

   getExamTypes(onlyPublic: boolean = false): ExamType[] {
      const examTypes = onlyPublic ? this.publicExamTypesSubject.getValue() : this.examTypesSubject.getValue();
      if (!examTypes) return [];

      return examTypes;
   }

   getExamTypeById(id?: number): ExamType | undefined {
      const examTypes = this.getExamTypes();
      if (!examTypes) return undefined;

      return examTypes.find(type => type.id === id);
   }

   getExamTypeByName(name: string): ExamType | undefined {
      return this.getExamTypes()?.find(type => type.name === name);
   }

   getIdByName(name: string): number | undefined {
      return this.getExamTypeByName(name)?.id;
   }

   getNameById(id: number): string | undefined {
      return this.getExamTypeById(id)?.name;
   }
}