import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { DefaultLayoutComponent } from './default-layout/default-layout.component'
import { DefaultHeaderComponent } from './default-layout/default-header/default-header.component'
import { DefaultFooterComponent } from './default-layout/default-footer/default-footer.component'
import { DefaultSidebarComponent } from './default-layout/default-sidebar/default-sidebar.component'
import { HeaderMobileComponent } from './default-layout/header-mobile/header-mobile.component'
import { CustomValidators } from 'src/app/utils/customValidators'
import { SharedModule } from './shared/shared.module'

// ng bootstrap tooltip module
import { NgbModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap'

// translation module
import { TranslateModule, TranslateLoader } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { NotificationsSidebarComponent } from './shared/sidebars/notifications-sidebar/notifications-sidebar.component'
import { UserSettingsSidebarComponent } from './shared/sidebars/user-settings-sidebar/user-settings-sidebar.component'
import { AuthInterceptorService } from './services/auth-interceptor.service'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { LoaderInterceptor } from './services/loader.interceptor'
import { GenericModalComponent } from './shared/modals/generic-modal/generic-modal.component'
import { LocationStrategy, PathLocationStrategy } from '@angular/common'
import { ShepherdService } from 'angular-shepherd'

import * as Sentry from '@sentry/angular-ivy'
import { SocketInterceptorService } from './services/socket-interceptor.service'
import { ServiceWorkerModule } from '@angular/service-worker'
import { environment } from 'src/environments/environment'
import { WarningInfoSidebarComponent } from './shared/sidebars/warning-info-sidebar/warning-info-sidebar.component'
import { AppointmentWarningItemComponent } from './shared/sidebars/warning-info-sidebar/items/appointment-warning-item/appointment-warning-item.component'
import { ExamRequestWarningItemComponent } from './shared/sidebars/warning-info-sidebar/items/exam-request-warning-item/exam-request-warning-item.component'
import { ExamTypesService } from './services/exam-types.service'

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http)
}

export function initializeApp(examTypesService: ExamTypesService) {
  return (): Promise<void> => examTypesService.fetchExamTypes();
}

@NgModule({
  declarations: [
    AppComponent,
    DefaultLayoutComponent,
    DefaultHeaderComponent,
    DefaultFooterComponent,
    DefaultSidebarComponent,
    HeaderMobileComponent,
    NotificationsSidebarComponent,
    UserSettingsSidebarComponent,
    GenericModalComponent,
    WarningInfoSidebarComponent,
    AppointmentWarningItemComponent,
    ExamRequestWarningItemComponent
  ],
  exports: [],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    NgbModule,
    NgbToastModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: false
    }),
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production
    })
  ],
  providers: [
    ExamTypesService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [ExamTypesService],
      multi: true
    },
    SocketInterceptorService,
    CustomValidators,
    ShepherdService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
