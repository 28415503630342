import { ModuleWithProviders, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { UserSettingsSidebarComponent } from './sidebars/user-settings-sidebar/user-settings-sidebar.component'

// translation module
import { TranslateModule, TranslateLoader } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { HttpClient } from '@angular/common/http'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BreadcrumbHeaderComponent } from './components/breadcrumb-header/breadcrumb-header.component'
import { BreadcrumbFilterComponent } from '../custom-views/exams/reusable-components/breadcrumb-filter/breadcrumb-filter.component'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { ConvertExamModalComponent } from './modals/convert-exam-modal/convert-exam-modal.component'
import { ExamTableComponent } from '../custom-views/exams/reusable-components/exam-table/exam-table.component'
import { RecentTicketsSidebarComponent } from './sidebars/recent-tickets-sidebar/recent-tickets-sidebar.component'
import { AssociatedStoresSidebarComponent } from './sidebars/associated-stores-sidebar/associated-stores-sidebar.component'
import { InvoiceInfoSidebarComponent } from './sidebars/invoice-info-sidebar/invoice-info-sidebar.component'
import { ComplaintsBookInfoSidebarComponent } from './sidebars/complaints-book-info-sidebar/complaints-book-info-sidebar.component'
import { AddComplaintsBookModalComponent } from './modals/add-complaints-book-modal/add-complaints-book-modal.component'
import { StoreInfoSidebarComponent } from './sidebars/store-info-sidebar/store-info-sidebar.component'
import { MaterialInfoSidebarComponent } from './sidebars/material-info-sidebar/material-info-sidebar.component'
import { AddMaterialModalComponent } from './modals/add-material-modal/add-material-modal.component'
import { PatientInfoSidebarComponent } from './sidebars/patient-info-sidebar/patient-info-sidebar.component'
import { PersonInfoSidebarComponent } from './sidebars/person-info-sidebar/person-info-sidebar.component'
import { CodeInfoSidebarComponent } from './sidebars/code-info-sidebar/code-info-sidebar.component'
import { AddCodeModalComponent } from './modals/add-code-modal/add-code-modal.component'
import { ToastsComponent } from './toast/toasts/toasts.component'
import { SafeHtml } from '../pipes/safeHtml.pipe'
import { SortByPipe } from '../pipes/sort-by.pipe'
import { FormControlPipe } from '../pipes/form-control.pipe'
import { ExamTypePipe } from '../pipes/exam-type.pipe'
import { AppointmentTypePipe, AppointmentTypeWithExtraInfoPipe } from '../pipes/appointment-type.pipe'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { SimplifiedTicketSidebarComponent } from './sidebars/simplified-ticket-sidebar/simplified-ticket-sidebar.component'
import { GenderPipe } from '../pipes/gender.pipe'
import { AgePipe } from '../pipes/age.pipe'
import { PdfPreviewModalComponent } from './modals/pdf-preview-modal/pdf-preview-modal.component'
import { CheckExamBlockedPipe } from '../pipes/check-exam-blocked.pipe'
import { ReportStatePipe } from '../pipes/report-state.pipe'
import { ImagePreviewComponent } from './modals/image-preview/image-preview.component'
import { SimilarExamFoundComponent } from './modals/similar-exam-found/similar-exam-found.component'
import { MPaginatorItemsPerPageDirective } from '../directives/m-paginator-items-per-page.directive'
import { ExamPriorityPipe } from '../pipes/exam-priority-pipe.pipe'
import { ShowForRoleDirective, HideForRoleDirective } from './directives/show-for-role.directive'
import { ConfirmExamWithoutFileComponent } from './modals/confirm-exam-without-file/confirm-exam-without-file.component'
import { StoreStatePipe } from '../pipes/store-state.pipe'
import { CheckExamValueInputDirective } from '../directives/check-exam-value-input.directive'
import { DraggableDirective } from '../directives/dragabble.directive'
import { CheckExamVisitedPipe } from '../pipes/check-exam-visited.pipe'
import { ExamStatePipe } from '../pipes/exam-state.pipe'
import { MessageToStorePipe } from '../pipes/message-to-store.pipe'
import { MessageToDoctorPipe } from '../pipes/message-to-doctor.pipe'
import { MatPaginatorModule } from '@angular/material/paginator'
import { UserOfflineModalComponent } from './modals/user-offline-modal/user-offline-modal.component'
import { RejectedReasonComponent } from './modals/rejected-reason/rejected-reason.component'
import { ExamPropertyValuePipe } from '../pipes/exam-property-value.pipe'
import { UserRolePipe } from '../pipes/user-role.pipe'
import { CheckPlacementDatePipe } from '../pipes/check-placement-date.pipe'
import { ReplaceStringCharactersPipe } from '../pipes/replace-string-characters.pipe'
import { SendReportViaEmailComponent } from './modals/send-report-via-email/send-report-via-email.component'
import { RejectedReasonPipe } from '../pipes/rejected-reason.pipe'
import { AddAnnouncementModalComponent } from './modals/add-announcement-modal/add-announcement-modal.component'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { ExamRepetitionTimelineModalComponent } from './modals/exam-repetition-timeline-modal/exam-repetition-timeline-modal.component'
import { FileNameFromFilePathPipe } from '../pipes/fileNameFromDocumentPath.pipe'
import { CharacterLimitDirective } from './directives/character-limit.directive'
import { ReopenedReasonComponent } from './modals/reopened-reason/reopened-reason.component'
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search'
import { MatSelectModule } from '@angular/material/select'
import { SelectedGenericId } from '../pipes/selectedGenericId.pipe'
import { StoreInfoComponent } from './modals/store-info/store-info.component'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatTooltipModule } from '@angular/material/tooltip'
import { PdfViewerModule } from 'ng2-pdf-viewer'
import { AppointmentPropertyValuePipe } from '../pipes/appointment-property-value.pipe'
import { StoreSubmittedExamStatePipe } from '../pipes/store-submitted-exam-state.pipe'
import { PresenceDelarationModalComponent } from './modals/presence-declaration-modal/presence-declaration-modal.component'
import { ExamRequestGroupStatePipe } from '../pipes/exam-request-group-state.pipe'
import { AddExternalExamTypeModalComponent } from './modals/add-external-exam-type-modal/add-external-exam-type-modal.component'
import { ExamPriorityDescriptionPipe } from '../pipes/exam-priority-description-pipe.pipe'
import { AppointmentRowComponent } from '../custom-views/teleheart/reusable-components/appointment-row/appointment-row.component'
import { MatMenuModule } from '@angular/material/menu'
import { AppointmentsHistoryComponent } from '../custom-views/teleheart/reusable-components/appointments-history/appointments-history.component'
import { EditAppointmentInfoModalComponent } from '../custom-views/teleheart/modal/edit-appointment-info-modal/edit-appointment-info-modal.component'

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http)
}

@NgModule({
  declarations: [
    BreadcrumbHeaderComponent,
    ConvertExamModalComponent,
    BreadcrumbFilterComponent,
    RecentTicketsSidebarComponent,
    AssociatedStoresSidebarComponent,
    InvoiceInfoSidebarComponent,
    ComplaintsBookInfoSidebarComponent,
    AddComplaintsBookModalComponent,
    StoreInfoSidebarComponent,
    MaterialInfoSidebarComponent,
    AddMaterialModalComponent,
    PatientInfoSidebarComponent,
    PersonInfoSidebarComponent,
    CodeInfoSidebarComponent,
    AddCodeModalComponent,
    AppointmentRowComponent,
    ToastsComponent,
    SafeHtml,
    SortByPipe,
    FormControlPipe,
    ExamTypePipe,
    AppointmentTypePipe,
    AppointmentTypeWithExtraInfoPipe,
    GenderPipe,
    AgePipe,
    SimplifiedTicketSidebarComponent,
    AppointmentPropertyValuePipe,
    PdfPreviewModalComponent,
    ImagePreviewComponent,
    CheckExamBlockedPipe,
    ReportStatePipe,
    SimilarExamFoundComponent,
    MPaginatorItemsPerPageDirective,
    ExamTableComponent,
    ExamPriorityPipe,
    ExamPriorityDescriptionPipe,
    ShowForRoleDirective,
    HideForRoleDirective,
    ConfirmExamWithoutFileComponent,
    StoreStatePipe,
    CheckExamValueInputDirective,
    DraggableDirective,
    CheckExamVisitedPipe,
    ExamStatePipe,
    MessageToStorePipe,
    MessageToDoctorPipe,
    RejectedReasonPipe,
    SelectedGenericId,
    UserOfflineModalComponent,
    RejectedReasonComponent,
    ReopenedReasonComponent,
    StoreInfoComponent,
    ExamPropertyValuePipe,
    CheckPlacementDatePipe,
    UserRolePipe,
    ReplaceStringCharactersPipe,
    SendReportViaEmailComponent,
    AddAnnouncementModalComponent,
    ExamRepetitionTimelineModalComponent,
    PresenceDelarationModalComponent,
    FileNameFromFilePathPipe,
    CharacterLimitDirective,
    StoreSubmittedExamStatePipe,
    ExamRequestGroupStatePipe,
    AddExternalExamTypeModalComponent,
    AppointmentsHistoryComponent,
    EditAppointmentInfoModalComponent
  ],
  imports: [
    CommonModule,
    MatAutocompleteModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    MatPaginatorModule,
    NgxMatSelectSearchModule,
    MatSelectModule,
    MatProgressBarModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatTooltipModule,
    MatInputModule,
    MatMenuModule,
    PdfViewerModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: false
    })
  ],
  exports: [
    TranslateModule,
    FormsModule,
    MatMenuModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    BreadcrumbHeaderComponent,
    BreadcrumbFilterComponent,
    AppointmentRowComponent,
    ToastsComponent,
    SafeHtml,
    MatAutocompleteModule,
    FormControlPipe,
    GenderPipe,
    ExamTypePipe,
    AppointmentTypePipe,
    AppointmentTypeWithExtraInfoPipe,
    AgePipe,
    CheckExamBlockedPipe,
    AppointmentPropertyValuePipe,
    MatProgressBarModule,
    SelectedGenericId,
    ReportStatePipe,
    SortByPipe,
    MPaginatorItemsPerPageDirective,
    ExamTableComponent,
    ExamPriorityPipe,
    ExamPriorityDescriptionPipe,
    ShowForRoleDirective,
    HideForRoleDirective,
    CheckExamValueInputDirective,
    DraggableDirective,
    CheckExamVisitedPipe,
    ExamStatePipe,
    MessageToStorePipe,
    StoreInfoComponent,
    RejectedReasonPipe,
    MessageToDoctorPipe,
    MatPaginatorModule,
    ExamPropertyValuePipe,
    UserRolePipe,
    NgxMatSelectSearchModule,
    MatSelectModule,
    CheckPlacementDatePipe,
    ReplaceStringCharactersPipe,
    FileNameFromFilePathPipe,
    CharacterLimitDirective,
    MatExpansionModule,
    PdfViewerModule,
    StoreSubmittedExamStatePipe,
    ExamRequestGroupStatePipe,
    AddExternalExamTypeModalComponent,
    AppointmentsHistoryComponent,
    EditAppointmentInfoModalComponent
  ]
})
export class SharedModule { }
